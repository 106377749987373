import clsx from "clsx";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  HeaderUserLinks,
  ThemeModeSwitcher,
} from "../../../partials";
import { useLayout } from "../../core";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {getUser} from '../../../../app/modules/auth/core/AuthHelpers'
import { useAuth } from "../../../../app/modules/auth";
const itemClass = "ms-1 ms-md-4";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px";
const userAvatarClass = "symbol-35px";
const btnIconClass = "fs-2";

const API_URL =
  process.env.REACT_APP_API_URL + "/customers/info";

const Navbar = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [customerName, setCustomerName] = useState<any>([]);
  useEffect(() => {
    axios.get(`${API_URL}`)
    .then((d) => {
      const data = d.data.data;
      setCustomerName(data);
      
      if(data && data.cdn){
        sessionStorage.setItem('REACT_APP_CDN_LINK', data.cdn);
      }
    });
  }, [location]);

  const { config, classes } = useLayout();
  if (!config.app?.header?.display) {
    return null;
  }

  return (
    <div className="app-navbar flex-shrink-0">
      {( location.pathname !== '/customers/list'  && customerName) &&
       <div className={clsx("app-navbar-item", itemClass)}>
       <button
              className='btn btn-sm btn-light'
              type='button'
              data-bs-toggle='tooltip'
              title='Customer'
              onClick={()=>currentUser?.userType === 'SUPERADMIN' ? navigate('/customers/list'):''}
            >
              {/* <i className='bi bi-person fs-3'></i> */}
              {customerName.customerName}
            </button>
    
      </div>
}
{customerName && customerName.customerName && 
       <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={'btn btn-icon btn-custom btn-icon-primary btn-active-light btn-active-color-primary w-35px h-35px'}
        >
          <KTIcon iconName='element-11' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div>
}
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderUserLinks />
      </div> */}
      <div className={clsx("app-navbar-item", itemClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx("btn-active-light-primary btn-custom")}
        />
      </div>
      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <img src={toAbsoluteUrl("/media/svg/avatars/blank.svg")} alt="" />
        </div>
        <HeaderUserMenu />
      </div>
      
      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3'>
          <div
            className={btnClass}
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
